import { useContext, useEffect } from 'react'
import Context from '../AppContext'
import Container from '../components/Container'

const publicURL = process.env.PUBLIC_URL

export const DownloadAppView = () => {
  const { t } = useContext(Context)

  const InstallPC = async e => {
    e?.preventDefault()
    console.log(`${publicURL}/files/chaostricks.zip`);

    fetch(`${publicURL}/files/chaostricks.zip`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/zip',
      },
    })
      .then(data => {
        window.location.assign(data.url)
      })
      .catch(err => console.log(err.message))
  }

  useEffect(() => {
      InstallPC();
  }, [])

  return (
    <main className="download_app">
      <Container>
        <div className="config">
          <div className="config-body__container">
            <p className="config-body__container-text">{t('download_dont_start')}</p>
            <button
              className="config-body__button"
              onClick={() => InstallPC()}
              type="button"
            >
              {t('download_app')}
            </button>
          </div>
        </div>
      </Container>
    </main>
  )
}
